export default {
  data() {
    return {
      inputTimeout: null,
    }
  },

  watch: {
    search(val) {
      if (!val) {
        this.options.page === 1 ? this.paginateTo() : (this.options.page = 1)
      } else {
        if (this.inputTimeout) {
          clearTimeout(this.inputTimeout)
          this.inputTimeout = null
        }
        this.inputTimeout = setTimeout(() => {
          this.options.page === 1 ? this.paginateTo() : (this.options.page = 1)
        }, 1500)
      }
    },
  },
}
