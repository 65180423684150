import { toCamelCase } from '@/helpers/toCamelCase'

export default {
  computed: {
    sortField() {
      return toCamelCase(this.options.sortBy[0])
    },

    sortDirection() {
      if (this.options.sortBy.length === 1 && this.options.sortDesc.length === 1) {
        return this.options.sortDesc[0] ? 'desc' : 'asc'
      } else return ''
    },

    sortObject() {
      const field = this.options.sortBy?.length ? this.options.sortBy[0] : null
      if (field) {
        const header = this.headers ? this.headers.find((item) => item.value === field) : null
        if (header?.sortFields) {
          let res = {}
          header.sortFields.forEach((item) => {
            res = { ...res, [toCamelCase(item)]: this.sortDirection }
          })
          return res
        } else {
          return { [this.sortField]: this.sortDirection }
        }
      }
      return null
    },
  },
}
