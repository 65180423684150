<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="additionalInfoEventTypesList"
        :loading="loading"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="getAdditionalInfoEventTypesTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Групи</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <router-link
              :to="{ name: 'InfoConstructorEventsListAll', params: { id: currentCompanyId } }"
              class="text-decoration-none v-toolbar__title"
            >
              Події та статуси
            </router-link>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <v-dialog @click:outside="close" v-model="dialog" max-width="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on">Створити групу</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-radio-group v-model="locale" row hide-details>
                          <v-radio v-for="loc in localesDictionary" :key="loc.id" :label="loc.name" :value="loc.value"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.protocol"
                          :items="PROTOCOL_TYPES"
                          item-text="type"
                          item-value="type"
                          label="Протокол"
                          clearable
                          :disabled="editedIndex !== -1"
                          @input="$v.editedItem.protocol.$touch()"
                          @blur="$v.editedItem.protocol.$touch()"
                          :error-messages="protocolErrors"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model.number="editedItem.code"
                          label="Код групи"
                          :disabled="editedIndex !== -1"
                          @input="$v.editedItem.code.$touch()"
                          @blur="$v.editedItem.code.$touch()"
                          :error-messages="codeErrors"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.name[locale]"
                          label="Назва групи"
                          @input="$v.editedItem.name[$currentLocale].$touch()"
                          @blur="$v.editedItem.name[$currentLocale].$touch()"
                          :error-messages="nameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-textarea v-model="editedItem.description[locale]" label="Опис" outlined></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Групу буде видалено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
                  <v-btn text @click="closeDelete">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              path: `${ROUTER_LINKS.INFO_CONSTRUCTOR_EVENTS_LIST}/${item.id}`,
              query: { eventTypeName: `${item.name[$currentLocale]}`, eventTypeCode: `${item.code}` },
            }"
            class="text-decoration-none"
          >
            {{ item.name[$currentLocale] }}
          </router-link>
          <!--          <router-link :to="`${ROUTER_LINKS.INFO_CONSTRUCTOR_EVENTS_LIST}/${item.id}`" class="text-decoration-none">
            {{ item.name }}
          </router-link>-->
        </template>
        <template v-slot:item.description="{ item }">{{ item.description[$currentLocale] }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on"> mdi-pencil-outline </v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on"> mdi-delete-outline </v-icon>
            </template>
            <span>Видалити</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData"> Оновити </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { ADDITIONAL_INFO_EVENT_TYPE_SCHEMA } from '@/const/apiSchemas'
import { PROTOCOL_TYPES } from '@/const/constructor/protocol-types.enum'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import cloneDeep from '@/mixins/cloneDeep'
import resetTableData from '@/mixins/resetTableData'
import { validationMixin } from 'vuelidate'
import { required, integer } from 'vuelidate/lib/validators'
import AwaitingSearch from '@/mixins/constructor/awaitingSearch'
import BackButton from '@/components/common/BackButton'
import { CURRENT_LOCALE } from '@/const/globals'
import sortUtils from '@/mixins/sortUtils'

export default {
  name: 'InfoConstructorEventsGroupList',
  components: { BackButton },

  mixins: [resetTableData, cloneDeep, validationMixin, AwaitingSearch, cloneDeep, sortUtils],

  validations: {
    editedItem: {
      name: {
        [CURRENT_LOCALE]: { required },
      },
      protocol: { required },
      code: { required, integer },
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    ADDITIONAL_INFO_EVENT_TYPE_SCHEMA: ADDITIONAL_INFO_EVENT_TYPE_SCHEMA,
    PROTOCOL_TYPES: PROTOCOL_TYPES,
    search: '',
    dialog: false,
    dialogDelete: false,
    loading: false,
    headers: [
      { text: 'ID', align: 'start', value: 'id', sortable: true },
      { text: 'Назва групи', value: 'name', sortable: true },
      { text: 'Код групи', value: 'code', sortable: true },
      { text: 'Протокол', value: 'protocol', sortable: true },
      { text: 'Опис', value: 'description', sortable: false, width: 200 },
      { text: '', value: 'actions', sortable: false, width: '125px', align: 'center' },
    ],
    sortBy: 'id',
    sortDesc: false,
    editedIndex: -1,
    options: {},
    clickedEventTypeId: null,
    additionalInfoEventTypesList: [],
    editedItem: {},
    defaultItem: {},
    locale: CURRENT_LOCALE,
  }),

  computed: {
    ...mapGetters('infoConstructor', ['getAdditionalInfoEventTypesTotalCount']),
    ...mapState('infoConstructor', ['additionalInfoEventTypes']),
    ...mapState('dictionaries', ['localesDictionary']),

    formTitle() {
      return this.editedIndex === -1 ? 'Додати групу' : 'Редагувати групу'
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name[this.$currentLocale].$dirty) return errors
      !this.$v.editedItem.name[this.$currentLocale].required && errors.push('Це поле обов"язкове')
      return errors
    },
    protocolErrors() {
      const errors = []
      if (!this.$v.editedItem.protocol.$dirty) return errors
      !this.$v.editedItem.protocol.required && errors.push('Це поле обов"язкове')
      return errors
    },
    codeErrors() {
      const errors = []
      if (!this.$v.editedItem.code.$dirty) return errors
      !this.$v.editedItem.code.required && errors.push('Це поле обов"язкове')
      !this.$v.editedItem.code.integer && errors.push('Тільки ціле число')
      return errors
    },
  },

  watch: {
    options: {
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      },
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  created() {
    this.clone()
  },

  methods: {
    ...mapActions('infoConstructor', [
      'loadAdditionalInfoEventTypes',
      'createNewAdditionalInfoEventType',
      'updateSelectedAdditionalInfoEventType',
      'deleteSelectedAdditionalInfoEventType',
    ]),
    ...mapActions('logs', ['displayWarningAlert']),
    ...mapActions('dictionaries', ['loadDictionary']),

    async initialize() {
      this.additionalInfoEventTypesList = [...this.additionalInfoEventTypes]
    },

    clone() {
      this.editedItem = { ...this.cloneObjectDeepWithLocales(this.ADDITIONAL_INFO_EVENT_TYPE_SCHEMA, this.localesDictionary) }
      this.defaultItem = { ...this.cloneObjectDeepWithLocales(this.ADDITIONAL_INFO_EVENT_TYPE_SCHEMA, this.localesDictionary) }
    },

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const params = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
          search: this.search,
        }
        await this.loadAdditionalInfoEventTypes(params)
        await this.initialize()
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },

    editItem(item) {
      this.editedIndex = this.additionalInfoEventTypesList.indexOf(item)
      this.editedItem = this.cloneObjectDeep(item)
      this.clickedEventTypeId = item.id
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.additionalInfoEventTypesList.indexOf(item)
      this.clickedEventTypeId = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      await this.deleteSelectedAdditionalInfoEventType(this.clickedEventTypeId)
      await this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },

    close() {
      this.dialog = false
      this.locale = this.$currentLocale
      this.$nextTick(() => {
        this.editedItem = this.cloneObjectDeep(this.defaultItem)
        this.clone()
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(async () => {
        this.editedItem = this.cloneObjectDeep(this.defaultItem)
        await this.clone()
        this.editedIndex = -1
      })
    },

    async save() {
      this.$v.editedItem.$touch()
      if (this.$v.$anyError) return
      this.loading = true
      if (this.editedIndex > -1) {
        const payload = {
          eventTypeId: this.clickedEventTypeId,
          updatedEventType: {
            name: this.editedItem.name,
            description: this.editedItem.description,
          },
        }
        await this.updateSelectedAdditionalInfoEventType(payload)
      } else {
        await this.createNewAdditionalInfoEventType(this.editedItem)
      }
      this.close()
      await this.paginateTo()
      this.loading = false
    },
  },
}
</script>

<style scoped></style>
